<template>
  <CCard>
    <CCardHeader class="d-flex flex-wrap justify-content-start">
      <div class="d-flex align-items-center">
        <TMessage content="Other payment" bold />
        <TSpinner :loading="creating" />
      </div>
      <div class="ml-auto">
        <TButtonClear
          variant="outline"
          color="primary"
          class="mr-1"
          @click="clearInput"
        />
        <TButton
          content="Create"
          icon="cis-plus"
          @click="create"
          :options="{ disabled: creating || !input.debit_id }"
        />
      </div>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol md="7">
          <CRow>
            <CCol>
              <div>
                <TInputRadio
                  label="Object"
                  :list="transactionableTypes"
                  :value.sync="input.transactionable_type"
                  custom
                />
              </div>
              <SSelectCustomer
                v-if="input.transactionable_type === 'user'"
                :value.sync="input.transactionable_id"
                class="mb-3"
              />
              <SSelectSupplier
                v-if="input.transactionable_type === 'supplier'"
                :value.sync="input.transactionable_id"
                class="mb-3"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <CRow>
                <CCol md="3">
                  <TInputDateTime
                    label="Voucher date"
                    class="mb-3"
                    :value.sync="input.voucher_date"
                  />
                </CCol>
                <CCol md="3">
                  <SSelectCurrency
                    label="Currency"
                    :prepend="[]"
                    :value.sync="input.currency_id"
                  />
                </CCol>
                <CCol md="6">
                  <TInputMoney
                    label="Amount"
                    class="mb-3"
                    :currency="input.currency_id"
                    :value.sync="input.amount"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <TInputTextarea
            label="Description"
            placeholder="Description"
            class="mb-3"
            :value.sync="input.description"
          />
          <SCardSelectReferenceReceipt :receipt.sync="temp" />
          <hr />
          <CRow>
            <CCol md="6">
              <SSelectAccount
                label="Debit"
                noFetchStore
                class="mb-3"
                :value.sync="input.debit_id"
              />
            </CCol>
            <CCol md="6">
              <SSelectAccountGroup
                :group="['111']"
                label="Credit"
                class="mb-3"
                :value.sync="input.credit_id"
              />
            </CCol>
          </CRow>
        </CCol>
        <CCol md="5">
          <TInputFile label="Receipts" class="mb-3" :value.sync="receipts" />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  data() {
    return {
      temp: {
        receiptable_id: "",
        receiptable_type: "",
      },
      input: {
        credit_id: "111",
      },
      items: [],
      receipts: [],
      transactionableTypes: [
        { value: "user", label: this.$t("Customer") },
        { value: "supplier", label: this.$t("Supplier") },
      ],
    };
  },
  computed: {
    creating() {
      return this.$store.getters["accounting.transactions.creating"];
    },
  },
  watch: {
    "temp.receiptable_type": function () {
      this.temp.receiptable_id = "";
    },
  },
  methods: {
    create() {
      this.$store
        .dispatch("accounting.transactions.create", this.getDataCreate())
        .then(() => {
          this.clearInput();
        });
    },
    getDataCreate() {
      let params = {
        type_id: "other-receivables",
        ...this.input,
      };
      this.receipts.forEach((receipt, index) => {
        params[`receipts[${index}][receiptable_type]`] = "file";
        params[`receipts[${index}][file]`] = receipt.file;
      });

      if (this.temp.receiptable_type && this.temp.receiptable_id) {
        const nextIndexReceipt = this.receipts.length;
        params[`receipts[${nextIndexReceipt}][receiptable_type]`] =
          this.temp.receiptable_type;
        params[`receipts[${nextIndexReceipt}][receiptable_id]`] =
          this.temp.receiptable_id;
      }

      return this.lodash.pickBy(params);
    },
    clearInput() {
      this.temp = {
        receiptable_id: "",
        receiptable_type: "",
      };
      this.input = {
        credit_id: "111",
      };
      this.receipts = [];
    },
  },
};
</script>
