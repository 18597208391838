<template>
  <div class="w-100">
    <TTabs
      :tabs="tabs"
      :activeTab="$route.query.tab"
      @change-tab="changeTab"
      mountOnEnter
    >
      <template #pre-payment>
        <TransferCashToDeposit />
      </template>
      <template #other>
        <Other />
      </template>
    </TTabs>
  </div>
</template>

<script>
import TransferCashToDeposit from "./TransferCashToDeposit.vue";
import Other from "./Other.vue";

export default {
  components: {
    TransferCashToDeposit,
    Other,
  },
  data() {
    return {
      tabs: [
        {
          key: "pre-payment",
          name: "Transfer cash to deposit",
          icon: "cisLibraryBooks",
        },
        {
          key: "other",
          name: "Other payment",
          icon: "cisLibraryBooks",
        },
      ],
    };
  },
  methods: {
    changeTab(tab) {
      this.$router.push({ query: { tab: tab } });
    },
  },
};
</script>
